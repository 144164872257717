<template>

  <div class='home' style="height: 100%; width: 100%">
    <el-header style="padding: 0; margin: 0 auto">
      <header-layout></header-layout>
    </el-header>
    <div class='login clearfix'>
      <div class='login-wrap'>
        <el-row justify="center" type="flex">
          <el-form ref="loginForm" :model="user" label-width="66px" status-icon style="text-align: center">
            <h2>登&nbsp;&nbsp;录</h2>
            <hr>
            <el-form-item prop="email">
              <span slot="label" style="font-size:large;">邮&nbsp;&nbsp;箱&nbsp;&nbsp;</span>
              <el-input v-model="user.email" placeholder="请输入邮箱" prefix-icon></el-input>
            </el-form-item>
            <el-form-item id="password" prop="password">
              <span slot="label" style="font-size:large;">密&nbsp;&nbsp;码&nbsp;&nbsp;</span>
              <el-input v-model="user.password" placeholder="请输入密码" show-password
                        @keyup.enter.native="doLogin"></el-input>
            </el-form-item>
            <!-- <el-form-item> -->
            <div style="padding: 0.5rem"></div>
            <router-link style="margin-top:0.5rem; margin-bottom: 0.5rem;" to="/resetpassword">找回密码</router-link>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <router-link style="margin-top:0.5rem; margin-bottom: 0.5rem;" to="/register">注册账号</router-link>
            <div style="padding: 0.5rem"></div>
            <!-- </el-form-item>   -->
            <el-form-item style="margin: 0 auto">
              <el-button style="background-color:#1E90FF; font-size: medium;" type="primary" @click="doLogin">登&nbsp;&nbsp;录</el-button>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </div>
    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
// import Loading from './Loading.vue'
import {login} from '@/api/public'
import { getDate } from '@/api/company'
import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayoutSlim from '../../components/FooterLayoutSlim.vue'
import FooterLayout from '../../components/FooterLayout.vue'

export default {
  name: 'login',
  components: {
    FooterLayout,
    HeaderLayout,
    FooterLayoutSlim
  },
  data() {
    return {
      // isLogin: this.$$root.isLogin,
      user: {
        email: '',
        password: ''
      }

    }
  },

  methods: {

    //登录逻辑
    doLogin() {

      if (!this.user.email) {
        this.$message.error('请输入邮箱！')
        return
      } else if (!this.user.password) {
        this.$message.error('请输入密码！')
        return
      } else {
        login(this.user.email, this.user.password).then(res => {
          // console.log(res)
          if (res.data.code === 0) {
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            if (window.innerWidth < 1000) {
              this.$message.warning('屏幕宽度过窄，使用体验无法保证')
            }
            this.$store.commit('setID', {userID: res.data.data.id})
            this.$store.commit('setName', {name: res.data.data.name})
            this.$store.commit('set', {Login: true})
            // console.log(res.headers['user_login_token'])
            this.$store.commit('setToken', {token: res.headers['user_login_token']})
            localStorage.setItem('store', JSON.stringify(this.$store.state))
            getDate().then(res1=>{
              this.$store.commit('setDate',{date: res1.data})
              console.log(res1.data)
            })
            this.$router.push('/')
          }
        }).catch(() => {
          this.$message.error('您输入的邮箱或密码错误')
          // console.log("fail")
        })
      }

    }
  },

  mounted() {
    if (this.$store.state.isLogin) {
      this.$message.info(this.$store.state.name + '，欢迎回来！')
      this.$router.push('/user')
    }
  }

}
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  background: url("https://citi-1308543285.cos.ap-shanghai.myqcloud.com/userbg.png") repeat;
  overflow: hidden;
  animation: bg_anim 20s ease infinite;
  -webkit-animation: bg_anim 20s ease infinite;
  -moz-animation: bg_anim 20s ease infinite;
}

@keyframes bg_anim {
  0% {
    background-position: 0 0;
    opacity: 1;
    filter: brightness(1);
  }
  25% {
    background-position: 20% 0;
    opacity: 1;
    filter: brightness(0.8);
  }
  50% {
    background-position: 10% 10%;
    opacity: 1;
    filter: brightness(1.1);
  }
  75% {
    background-position: 20% 0;
    opacity: 1;
    filter: brightness(1);
  }
  100% {
    background-position: 0 0;
    opacity: 1;
    filter: brightness(1);
  }
}

.login-wrap {
  /* background: url("../assets/images/login_bg.jpg") no-repeat; */
  border-radius: 15px;
  background-size: cover;
  background-color: white;
  opacity: 0.8;
  width: 360px;
  height: 330px;
  margin: 215px auto;
  overflow: hidden;
  padding-top: 10px;
  line-height: 10px;
  box-shadow: 0 0 5px white;
}

#password {
  margin-bottom: 5px;
}

h3 {
  color: #0babeab8;
  font-size: 24px;
}

hr {
  background-color: #444;
  margin: 20px auto;
}

a {
  text-decoration: none;
  color: #aaa;
  font-size: 15px;
}

a:hover {
  color: coral;
}

.el-button {
  width: 80%;
  margin-left: -50px;
}

.el-form-item__label {
  color: white;
}
</style>
